






































import Vue from 'vue';
import packageJson from '../package.json';
import update from './mixins/update';

export default Vue.extend({
  name: 'App',

  data: () => ({
    appVersion: packageJson.version
  }),
  mixins:[update]
});
